import React from "react";
const defaultState = {
  activeMenu: "",
  changeActiveMenu: () => {}
};
const MenuContext = React.createContext(defaultState);

class MenuProvider extends React.Component {
  state = {
    activeMenu: ""
  };

  changeActiveMenu = activeMenu => {
    localStorage.setItem("activeMenu", JSON.stringify(activeMenu));
    this.setState({ activeMenu });
  };

  componentDidMount() {
    const activeMenu = JSON.parse(localStorage.getItem("activeMenu"));
    this.setState({ activeMenu: activeMenu });
  }

  render() {
    const { children } = this.props;
    const { activeMenu } = this.state;
    return (
      <MenuContext.Provider
        value={{
          activeMenu,
          changeActiveMenu: this.changeActiveMenu
        }}
      >
        {children}
      </MenuContext.Provider>
    );
  }
}
export default MenuContext;
export { MenuProvider };
